import * as React from "react"
import "./Header.scss";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const Header = () => (
    <header>
        <div className="RPS-Logo">
            <Link to="/">
                <StaticImage
                    src="../../../content/assets/images/roseville_pool_service_logo5.png"

                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="Roseville Pool Service"
                    placeholder="blurred"
                    layout="constrained"
                />
            </Link>
        </div>
    </header>
)


export default Header