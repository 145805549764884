/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const SEO = ({ lang, description, meta, keywords, title }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            keywords
            description
            author
          }
        }
      }
    `,
  );

  const metaTitle = title || site.siteMetadata.title;
  const metaDescription = description || site.siteMetadata.description;
  let metaKeywords = keywords || site.siteMetadata.keywords;
  const metaAuthor = site.siteMetadata.author;

  if (Array.isArray(metaKeywords)) {
    metaKeywords = metaKeywords.join(", ");
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      // titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `viewport`,
          content: `width=device-width,minimum-scale=1`,
        },
        {
          charset: "UTF-8",
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `author`,
          content: metaAuthor,
        },
        {
          property: `og:title`,
          content: `Roseville Pool Service Inc - Serving Roseville, Rocklin, Granite Bay, CA`,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: `https://rosevillepoolservice.com/`,
        },
        {
          property: `og:image`,
          content: `https://rosevillepoolservice.com/assets/image/roseville_pool_service_logo.jpg`,
        },
        {
          property: `og:description`,
          content: `We offer exceptional quality weekly swimming pool maintenance and repair services, taking care of all your swimming pool needs or you can choose the service level that suits you best.`,
        },
        {
          property: `og:site_name`,
          content: `Roseville Pool Service`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:site`,
          content: `@pliczka_family`,
        },
        {
          name: `twitter:title`,
          content: `Roseville Pool Service Inc - Serving Roseville, Rocklin, Granite Bay, CA`,
        },
        {
          name: `twitter:creator`,
          content: `@pliczka_family`,
        },
        {
          name: `twitter:description`,
          content: `We offer exceptional quality weekly swimming pool maintenance and repair services, taking care of all your swimming pool needs or you can choose the service level that suits you best.`,
        },
        {
          name: `twitter:image`,
          content: `https://rosevillepoolservice.com/assets/image/roseville_pool_service_logo.jpg`,
        },
        {
          name: `google-site-verification`,
          content: `JKiGyqWocLZG17hLF0ktZ3fSAlSRROMBFB-kug2UQaI`,
        },
      ]
        .concat(
          metaKeywords.length > 0
            ? {
              name: `keywords`,
              content: metaKeywords,
            }
            : [],
        )
        .concat(meta)}
    >
      {/* <link rel="shortcut icon" href="/assets/image/favicon.ico" /> */}
      <link href="https://rosevillepoolservice.com/" />
      <base href="/" />
    </Helmet>
  );
};

SEO.propTypes = {
  lang: PropTypes.string,
  description: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
};

SEO.defaultProps = {
  lang: "en",
  meta: [],
  keywords: [],
  description: "",
};

export default SEO;
