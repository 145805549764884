import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "react-bootstrap";
import "./Footer.scss";

const Footer = ({ frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const {
    addressLine1,
    addressLine2,
    phoneNumber,
    service,
    content,
    copyright,
    // privacyHref,
    // privacyText,
    // termsHref,
    // termsText,
    // social: { facebook, github, linkedin, medium, twitter },
  } = frontmatter;

  return (
    <div>
      <footer className="footer">
        <Container className="centered text-center">
          <h4> {content} </h4>
          <br />
          <br />
          <Row>
            <Col md={12}>
              <Row>
                <Col md={6} dangerouslySetInnerHTML={{ __html: service }} />
                <Col md={6}>
                  <p> {addressLine1} </p>
                  <p> {addressLine2} </p>
                  <p> {phoneNumber} </p>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              {/* <div className="social-icons-wrapper text-center">
                <a href="https://plus.google.com/roseville-pool-service" target="_self">
                  <span className="glyphicon glyphicon-lg air-icon-social-google-plus" />
                </a>
                <a href="https://twitter.com/roseville-pool-service" target="_self">
                  <span className="glyphicon glyphicon-lg air-icon-social-twitter" />
                </a>
                <a href="https://www.facebook.com/roseville-pool-service" target="_self">
                  <span className="glyphicon glyphicon-lg air-icon-social-facebook" />
                </a>
                <a href="https://www.linkedin.com/company/roseville-pool-service" target="_self">
                  <span className="glyphicon glyphicon-lg air-icon-social-linkedin" />
                </a>
              </div> */}
              <br />
              <p className="text-center copyright">
                <b> {copyright} </b>
              </p>
            </Col>
          </Row>
        </Container>
      </footer>

      {/* <footer className="footer py-3">
        <Container>
          <Row className="align-items-center text-center">
            <Col lg={5} className="text-lg-left">
              {copyright}
            </Col>
            <Col lg={3} className="my-3 my-lg-0">
              {twitter ? <SocialIcons.Twitter userName={twitter} /> : null}
              {facebook ? <SocialIcons.Facebook userName={facebook} /> : null}
              {linkedin ? <SocialIcons.Linkedin userName={linkedin} /> : null}
              {github ? <SocialIcons.Github userName={github} /> : null}
              {medium ? <SocialIcons.Medium userName={medium} /> : null}
            </Col>
            <Col lg={4} className="text-lg-right">
              <a className="mr-3" href={privacyHref}>
                {privacyText}
              </a>
              <a href={termsHref}>{termsText}</a>
            </Col>
          </Row>
        </Container>
      </footer> */}
    </div>
  );
};

Footer.propTypes = {
  frontmatter: PropTypes.object,
};

Footer.defaultProps = {
  frontmatter: null,
};

export default Footer;
