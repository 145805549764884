import React from "react";
import PropTypes from "prop-types";
import { Navbar, Nav } from "react-bootstrap";

import useWindowOnScroll from "hooks/useWindowOnScroll";
import Icon from "components/Icon";
import NavItem from "components/NavItem";

import "./Navbar.scss";

const MyNavbar = ({ anchors, anchorTexts, frontmatter, extraItems }) => {
  const { menuText } = frontmatter;


  const [expanded, setExpanded] = React.useState(false);
  const toggleMenu = React.useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);
  const closeMenu = React.useCallback(() => {
    setExpanded(false);
  }, []);

  const [, setShrink] = React.useState(false);
  const handleWindowScroll = React.useCallback(() => {
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    setShrink(scrollTop > 100);
  }, []);
  useWindowOnScroll(handleWindowScroll);

  return (
    <Navbar
      // className={clsx("navbar-root", { "navbar-shrink": shrink })}
      className="navbar-root navbar-default"
      expand="lg"
      // fixed="top"
      expanded={expanded}
    >
      {/* <Container> */}
      {/* <Navbar.Brand className="cursor-pointer" onClick={handleBrandClick}>
          {brand}
        </Navbar.Brand> */}
      <Navbar.Toggle onClick={toggleMenu} aria-label="Toggle navigation">
        <Icon iconName="BarsIcon" className="hamburger-icon" />
        <span> {menuText} </span>
      </Navbar.Toggle>
      <Navbar.Collapse>
        <Nav>
          {anchors.map((anchor, i) => (
            <NavItem key={anchor} to={anchor} onClick={closeMenu}>
              {anchorTexts[i]}
            </NavItem>
          ))}
        </Nav>
        {extraItems}
      </Navbar.Collapse>
      {/* </Container> */}
    </Navbar>
  );
};

MyNavbar.propTypes = {
  anchors: PropTypes.arrayOf(PropTypes.string),
  anchorTexts: PropTypes.arrayOf(PropTypes.string),
  frontmatter: PropTypes.object,
  extraItems: PropTypes.any,
};

MyNavbar.defaultProps = {
  anchors: [],
  anchorTexts: [],
  frontmatter: {},
  extraItems: null,
};

export default MyNavbar;
