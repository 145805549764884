import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import Header from "views/Header";
import Navbar from "views/Navbar";
import Footer from "views/Footer";
import SEO from "components/SEO";

import "utils/fixFontAwesome";
import breakDownAllNodes from "utils/breakDownAllNodes";

import "../../style/main.scss";
import "./Layout.scss";

/**
 * get file name list from content/sections folder
 */
export const query = graphql`
  query LayoutQuery {
    allMarkdownRemark(sort: { order: ASC, fields: [fields___directoryName, fields___fileName] }) {
      nodes {
        frontmatter {
          addressLine1
          addressLine2
          phoneNumber
          service
          brand
          anchor
          anchorText
          content
          copyright
          header
          
          jumpToAnchor
          jumpToAnchorText
          menuText
        }
        fields {
          fileName
          directoryName
        }
      }
    }

    markdownRemark(fields: { fileName: { eq: "Meta" } }) {
      frontmatter {
        routes {
          commitment {
            title
            description
            keywords
          }
          contactus {
            title
            description
            keywords
          }
          home {
            title
            description
            keywords
          }
          links {
            title
            description
            keywords
          }
          pooltips {
            title
            description
            keywords
          }
          reviews {
            title
            description
            keywords
          }
          weeklyservices {
            title
            description
            keywords
          }
        }
      }
    }
  }
`;

const Layout = ({ pathName, children }) => {
  pathName = pathName.toLowerCase();

  return (
    <StaticQuery
      query={query}
      render={(data) => {
        const {
          allMarkdownRemark: { nodes },
          markdownRemark: {
            frontmatter: { routes },
          },
        } = data;

        const { title, description, keywords } = routes[pathName] ? routes[pathName] : {};

        const {
          navBarNode,
          anchors,
          anchorTexts,
          footerNode,
        } = breakDownAllNodes(nodes);

        return (
          <>
            <SEO title={title} keywords={keywords} description={description} />
            <Header />
            <Navbar
              anchors={anchors}
              anchorTexts={anchorTexts}
              frontmatter={navBarNode.frontmatter}
            />
            <div className="main">
              <main>{children}</main>
            </div>
            <Footer frontmatter={footerNode.frontmatter} />
          </>
        );
      }}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pathName: PropTypes.node.isRequired,
};

Layout.defaultProps = {};

export default Layout;
